import { Component, OnInit, HostListener } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil, delay } from "rxjs/operators";
import { AnimationOptions } from "ngx-lottie";
import { NbThemeService } from "@nebular/theme";
import { WindowResizeService } from "src/app/core/services/window-resize.service";
import { PagesHeaderService } from "src/app/core/services/pages-header.service";
import { DataTransferService } from "src/app/core/services/data-transfer.service";
import { IWhiteLabel } from "src/app/core/models";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: ["./support.component.scss"],
})
export class SupportComponent implements OnInit {
  public lottieConfig: AnimationOptions;
  public readonly optionsLight: AnimationOptions = {
    path: "./assets/animations/support-black.json",
    renderer: "canvas",
  };
  public supportPhone = "+3728807904";
  public supportEmail = "support@niko-tech.eu";
  public isShowingMenu: boolean;
  private unsubscribe$ = new Subject<void>();
  public isMobile: boolean;
  public supportItems: any = [];
  public whitelabel: string;

  @HostListener("document:click")
  clickout() {
    this.clickInside();
  }

  constructor(
    private readonly windowResizeService: WindowResizeService,
    private readonly dataTransferService: DataTransferService
  ) {
    this.lottieConfig = {
      path: "./assets/animations/support-black.json",
      renderer: "canvas",
    };
    this.windowResizeService
      .getIsMobile()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.isMobile = res;
      });
  }

  ngOnInit() {
    this.supportItems = this.getSupportItems();

    this.dataTransferService
      .onDataObservable$("whiteLabel")
      .subscribe((res: IWhiteLabel) => {
        this.whitelabel = res.titleName;
        this.supportEmail = res.supportEmail;
        this.supportPhone = res.phoneNumber;
        this.supportItems = this.getSupportItems(
          res.supportEmail,
          res.phoneNumber
        );
        if (res.supportJson) {
          this.lottieConfig = {
            animationData: res.supportJson,
          };
        }
      });
  }

  private getSupportItems(
    email = this.supportEmail,
    phone = this.supportPhone
  ) {
    return [
      {
        icon: "mail",
        title: "Email",
        method: "mail",
        link: `mailto:${email}`,
      },
      {
        icon: "contact",
        title: phone,
        method: "contact",
        link: `tel:${phone}`,
      },
    ];
  }

  public toggleMenu(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.isShowingMenu = !this.isShowingMenu;
  }

  private clickInside() {
    this.isShowingMenu = false;
    const elements = document.querySelectorAll(
      "div.dropdown-support-menu"
    ) as NodeListOf<HTMLElement>;
    elements.forEach((element) => {
      if (element) {
        if (element.classList.contains("show-menu")) {
          element.classList.remove("show-menu");
        }
      }
    });
    const userInfo = document.querySelectorAll(
      "div.support-menu"
    ) as NodeListOf<HTMLElement>;
    userInfo.forEach((element) => {
      if (element) {
        if (element.classList.contains("active-menu")) {
          element.classList.remove("active-menu");
        }
      }
    });
  }

  onSupport(item) {
    if (item.method === "mail") {
      window.open(item.link, "_self");
    } else {
      window.open(item.link, "_blank");
    }
  }
}
