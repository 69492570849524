import { Component, OnInit } from "@angular/core";
import { finalize } from "rxjs/operators";
import { IWhiteLabel } from "src/app/core/models";
import { DataTransferService } from "src/app/core/services/data-transfer.service";

@Component({
  selector: "app-logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"],
})
export class LogoComponent implements OnInit {
  public whiteLabel: IWhiteLabel;
  public isLoading: boolean;
  public serverError;

  constructor(private readonly dataTransferService: DataTransferService) {}

  ngOnInit() {
    this.initData();
  }

  private getWhiteLabel() {
    this.isLoading = true;
    this.dataTransferService
      .onDataObservable$("whiteLabel")
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((res: IWhiteLabel) => (this.whiteLabel = res));
  }

  private initData() {
    this.isLoading = true;
    this.dataTransferService
      .onDataObservable$("serverError")
      .subscribe((serverError) => {
        serverError ? (this.serverError = true) : this.getWhiteLabel();
        this.isLoading = false;
      });
  }
}
