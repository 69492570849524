import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { map } from "rxjs/operators";
import { LocaleService } from "src/app/core/services/locale.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-success",
  templateUrl: "./success.component.html",
  styleUrls: ["./success.component.scss"],
})
export class SuccessComponent implements OnInit {
  public readonly successHref = environment.successHref;
  private redirectUrl: string | null;

  constructor(
    protected readonly localeService: LocaleService,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(map(({ redirectUrl }) => redirectUrl))
      .subscribe((redirectUrl) => {
        this.redirectUrl = redirectUrl;
      });
  }

  public get dictionary() {
    return this.localeService.dictionary;
  }

  public get goToRedirectUrl() {
    return this.redirectUrl ?? this.successHref;
  }
}
