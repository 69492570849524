import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { IQuestion } from "src/app/core/models";

@Component({
  selector: "app-form-controls",
  templateUrl: "./form-controls.component.html",
})
export class FormControlsComponent {
  @Input() public readonly conf: IQuestion;
  @Input() public readonly form: FormGroup;
  @Output() public fileListChange: EventEmitter<any> = new EventEmitter();

  public getCheckboxLabel(conf) {
    if (conf.placeholder) {
      return conf.label.split("<a>");
    }
    return conf.label;
  }

  public emitFiles(files) {
    this.fileListChange.emit(files);
  }
}
