<ng-container *ngIf="!isMobile; else mobileContent">
  <div class="support-menu" [class.active-menu]="isShowingMenu">
    <div class="support-button" (click)="toggleMenu($event)">
      <ng-container *ngIf="!isShowingMenu; else text">
        <ng-lottie width="80px" height="80px" [options]="lottieConfig">
        </ng-lottie>
      </ng-container>

      <ng-template #text>
        <span class="btn-support-text">Support</span>
      </ng-template>
    </div>
    <ng-template [ngTemplateOutlet]="headerDropdownMenu"></ng-template>
  </div>

  <ng-template #headerDropdownMenu>
    <div
      class="dropdown-support-menu"
      id="dropdownHeaderMenu"
      [class.show-menu]="isShowingMenu"
      (click)="$event.stopPropagation()"
    >
      <ng-container *ngFor="let item of supportItems">
        <div
          class="dropdown-menu-item"
          (click)="onSupport(item)"
          *ngIf="item.title"
        >
          <span class="icon-wrap">
            <img
              src="/assets/icons/support/{{ item.icon }}.svg"
              [alt]="item.icon"
              class="link-icon"
            />
          </span>
          <span class="text">{{ item.title }}</span>
        </div>
      </ng-container>

      <a
        class="dropdown-menu-item"
        href="https://t.me/Tenexsupport"
        target="_blank"
        *ngIf="whitelabel === 'Tenex'"
      >
        <span class="icon-wrap">
          <img
            src="/assets/icons/support/telegram.svg"
            alt="Telegram"
            class="link-icon"
          />
        </span>
        <span class="text">Telegram</span>
      </a>
    </div>
  </ng-template>
</ng-container>

<ng-template #mobileContent>
  <div class="custom-support">
    <ng-container *ngIf="supportPhone && supportPhone?.length">
      <div class="support-label mb-2">Phone</div>
      <a class="support-tel mb-4" href="tel:{{ supportPhone }}">{{
        supportPhone
      }}</a>
    </ng-container>

    <div class="support-label mb-2">Email</div>

    <div class="support-icon-wrap">
      <ng-container *ngFor="let item of supportItems">
        <span
          class="icon-wrap"
          (click)="onSupport(item)"
          *ngIf="item.method !== 'contact'"
        >
          <img
            src="/assets/icons/support/{{ item.icon }}.svg"
            [alt]="item.icon"
            class="link-icon"
          />
        </span>
      </ng-container>
    </div>

    <ng-container *ngIf="whitelabel === 'Tenex'">
      <div class="support-label mb-2">Telegram</div>

      <a
        class="support-icon-wrap"
        href="https://t.me/Tenexsupport"
        target="_blank"
      >
        <span class="icon-wrap">
          <img
            src="/assets/icons/support/telegram.svg"
            alt="Telegram"
            class="link-icon"
          />
        </span>
      </a>
    </ng-container>
  </div>
</ng-template>
