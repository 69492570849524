import { Injectable } from '@angular/core';
import { Observable, fromEvent, of, merge } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WindowResizeService {
  private resize$: Observable<boolean>;
  private initial$: Observable<boolean>;

  constructor() {
    this.initial$ = of(window.innerWidth > 767 ? false : true);
    this.resize$ = fromEvent(window, 'resize').pipe(
      map((event: any) => {
        return event.target.innerWidth > 767 ? false : true;
      }),
    );
  }

  public getIsMobile() {
    return merge(this.initial$, this.resize$);
  }
}
