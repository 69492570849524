<div [formGroup]="form">
  <div [class]="'input input--' + conf.type">
    <ng-select
      appearance="outline"
      #agreeSelect
      [labelForId]="conf?.placeholder"
      [searchable]="true"
      [formControlName]="conf?.name"
      [placeholder]="placeholder"
      class="custom"
      [class.other]="isOtherSelected"
      (change)="onSelectChange($event)"
    >
      <ng-option
        *ngFor="let option of conf.answers"
        [value]="option?.value || option.name"
        [disabled]="isOptionDisabled(option.value)"
        ><span *ngIf="option?.alpha_3" class="visually-hidden">{{
          option?.alpha_3
        }}</span
        >{{ option?.name || option.text }}
        {{ isOptionDisabled(option.value) ? " - (busy)" : null }}</ng-option
      >
    </ng-select>
  </div>
</div>
<input
  *ngIf="isOtherSelected"
  class="input input--text"
  [ngClass]="{ 'ng-invalid': control.invalid, 'ng-touched': control.touched }"
  type="text"
  (change)="onDescriptionChange($event)"
  [placeholder]="'Type your variant of ' + conf.placeholder.toLowerCase()"
/>
