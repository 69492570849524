import { Component, Injector, OnInit } from "@angular/core";

import { SurveyService } from "src/app/core/services/survey.service";
import { DateComponent } from "../date/date.component";
import * as moment from "moment-timezone";
import {
  IConvertedMeetingDate,
  IConvertedOfficer,
  IMeetingDate,
  IOfficer,
  ITimeSelectItems,
  ITimezone,
} from "src/app/core/models";
import { DataTransferService } from "src/app/core/services/data-transfer.service";
import { SelectService } from "src/app/core/services/select.service";
import { IApiResponse } from "src/app/core/services/api.service";
import { NbDatepickerAdapter } from "@nebular/theme";
import { LocaleService } from "src/app/core/services/locale.service";

@Component({
  selector: "app-meeting-date",
  templateUrl: "./meeting-date.component.html",
  styleUrls: ["./meeting-date.component.scss"],
})
export class MeetingDateComponent extends DateComponent implements OnInit {
  public timeSelectItems: ITimeSelectItems[];
  public timezoneSelectItems: IApiResponse<ITimezone[]>;
  public selectedTimezone: string;
  public selectedTime: moment.Moment;
  private selectedOfficer: number;
  private cachedOfficers: IOfficer[];
  public officers: IConvertedOfficer[];

  private readonly complianceOfficerKey = "complianceOfficer";
  private readonly timeZoneKey = "timeZone";

  constructor(
    injector: Injector,
    private readonly service: SurveyService,
    private readonly selectService: SelectService,
    private readonly dataTransferService: DataTransferService,
    protected readonly localeService: LocaleService
  ) {
    super(injector, localeService);
  }

  public get dictionary() {
    return this.localeService.dictionary;
  }

  ngOnInit() {
    this.selectedTimezone = moment.tz.guess(true);
    super.ngOnInit();
  }

  protected async initFormControl() {
    this.minDate = this.today.add(1, "day");
    this.maxDate = moment().add(2, "month");
    await this.selectService
      .get<ITimezone[]>(this.timeZoneKey)
      .subscribe((timezones) => {
        this.timezoneSelectItems = timezones;
      });

    this.service.getCompliance().subscribe((officers) => {
      this.cachedOfficers = officers;
      this.officers = this.getBusyDates(officers);
    });

    this.form.controls.complianceOfficer.valueChanges.subscribe((officer) => {
      this.selectedOfficer = officer;
      if (this.selectedDate) {
        this.filterTime(moment(this.selectedDate));
      }
    });
  }

  public filter(date: moment.Moment): boolean {
    const hours = this.getHours(date);

    const officers = this.getFilteredOfficers();

    return hours.some((h) =>
      officers.some((officer) => !this.isOfficerBusy(officer, h))
    );
  }

  public onDateChange(date: moment.Moment) {
    super.onDateChange(date);
    this.timeSelectItems = null;
    this.selectedTime = null;
    this.form.controls[this.conf.name].setValue(null);
    this.filterTime(date);
  }

  public onTimeSelect(hour: moment.Moment) {
    if (hour) {
      const h = hour.toString();
      this.form.controls[this.conf.name].setValue(h);

      const availableOfficers = this.timeSelectItems.find(
        (item) => item.value === hour
      ).availableOfficers;

      this.dataTransferService.setData(
        this.complianceOfficerKey,
        availableOfficers
      );
    } else {
      this.selectedTime = null;
      this.form.controls[this.conf.name].setValue(null);
      this.dataTransferService.setData(this.complianceOfficerKey, []);
    }
  }

  public onTimeZoneSelect(timezone: string) {
    this.selectedTimezone = timezone;
    this.clearData();
    this.officers = this.getBusyDates(this.cachedOfficers);
  }

  /*  HELPERS  */

  private filterTime(date: moment.Moment) {
    const d = moment(
      date
        .format()
        .replace(
          /[+-]\d{2}:\d{2}/,
          moment.tz(this.selectedTimezone).format("Z")
        )
    ).tz(this.selectedTimezone);
    const hours = this.getHours(d);
    this.timeSelectItems = hours
      .map((h) => {
        const availableOfficers = [];
        this.officers.forEach((officer) =>
          officer.busy.some((date) =>
            h.isBetween(date.start, date.end, null, "[)")
          )
            ? null
            : availableOfficers.push(officer.id)
        );
        return {
          availableOfficers,
          value: h,
          text: h.format("HH:mm"),
        };
      })
      .filter(
        (item) =>
          item.availableOfficers.length &&
          item.availableOfficers.some((id) =>
            this.selectedOfficer ? id === this.selectedOfficer : true
          )
      )
      .sort((a, b) => (a.text > b.text ? 1 : -1));
  }

  private getBusyDates(officers: IOfficer[]): IConvertedOfficer[] {
    return officers.map((officer) => {
      const busy = officer.busy.map((date) => this.convertDate(date));

      return {
        id: officer.id,
        busy,
      };
    });
  }

  private convertDate(date: IMeetingDate): IConvertedMeetingDate {
    const start = moment.tz(date.start, this.selectedTimezone);
    const end = moment.tz(date.end, this.selectedTimezone);

    return { start, end };
  }

  private clearData() {
    this.selectedTime = null;
    this.timeSelectItems = null;
    this.selectedDate = null;
    this.pickerDay = null;
    this.pickerMonth = null;
    this.pickerYear = null;
    this.dataTransferService.setData(this.complianceOfficerKey, []);
    this.form.controls[this.conf.name].setValue(null);
  }

  private getHours(date: moment.Moment): moment.Moment[] {
    return new Array(24).fill("").map((item, i) => date.clone().hours(i));
  }

  private getFilteredOfficers(): IConvertedOfficer[] {
    if (this.selectedOfficer) {
      return this.officers.filter(({ id }) => id === this.selectedOfficer);
    }
    return this.officers;
  }

  private isOfficerBusy(officer: IConvertedOfficer, date: moment.Moment) {
    return officer.busy.find(({ start, end }) =>
      this.dateService.isBetween(date, start, end)
    );
  }
}
