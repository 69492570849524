import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataTransferService {
  private readonly subjectPostfix = "ChangedSubject";

  public setData(name: string, data: any) {
    this[name + this.subjectPostfix]?.next(data);
    this[name] = data;
  }

  public onDataObservable$<T>(name: string): Observable<T> {
    if (!this[name + this.subjectPostfix]) {
      this[name + this.subjectPostfix] = new Subject<T>();
    }
    return this[name + this.subjectPostfix]?.asObservable();
  }

  public getData<T>(name: string): T {
    return this[name];
  }
}
