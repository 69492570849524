import { Injectable } from "@angular/core";

export type LocaleType = "ru" | "en" | "uk";

export interface IDictionary {
  [key: string]: string;
}

@Injectable({
  providedIn: "root",
})
export class LocaleService {
  private _locale: LocaleType;

  private readonly _dictionaries: Record<LocaleType, IDictionary> = {
    en: {
      survey: "Survey",
      finish: "Finish",
      failedMsg: "Message has not been sent!",
      successMsg: "Message has successfully been sent!",
      surveyErrorMessage: "Survey already completed",
      serverErrorMessage:
        "Service temporarily unavailable. Please, try again later.",
      dd: "dd",
      month: "month",
      year: "year",
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
      success: "SUCCESS!",
      success_submited: "Your information has been successfully submitted.",
      success_notify:
        "Once your account is approved, we will notify you by email.",
      success_go_to: "GO TO",
    },
    ru: {
      survey: "Опрос",
      finish: "Завершить",
      failedMsg: "Сообщение успешно отправлено!",
      successMsg: "Сообщение не отправлено!",
      surveyErrorMessage: "Опрос уже пройден",
      serverErrorMessage:
        "Сервис временно недоступен. Пожалуйста, попробуйте позже.",
      dd: "день",
      month: "месяц",
      year: "год",
      january: "Январь",
      february: "Февраль",
      march: "Март",
      april: "Апрель",
      may: "Май",
      june: "Июнь",
      july: "Июль",
      august: "Август",
      september: "Сентябрь",
      october: "Октябрь",
      november: "Ноябрь",
      december: "Декабрь",
      success: "УСПЕХ!",
      success_submited: "Ваша информация получена.",
      success_notify:
        "Когда ваша учетная запись будет одобрена, мы отправим вам сообщение на электронную почту.",
      success_go_to: "ПЕРЕЙТИ ДАЛЬШЕ",
    },
    uk: {
      survey: "Опитування",
      finish: "Завершити",
      failedMsg: "Повiдомлення успiшно вiдiслано!",
      successMsg: "Повiдомлення не вiдiслано!",
      surveyErrorMessage: "Опитування вже пройдено",
      serverErrorMessage:
        "Сервiс тимчасово недоступний. Будь-ласка, спробуйте пiзнiше.",
      dd: "день",
      month: "місяць",
      year: "рік",
      january: "Січень",
      february: "Лютий",
      march: "Березень",
      april: "Квітень",
      may: "Травень",
      june: "Червень",
      july: "Липень",
      august: "Серпень",
      september: "Вересень",
      october: "Жовтень",
      november: "Листопад",
      december: "Грудень",
      success: "УСПІХ!",
      success_submited: "Вашу інформацію отримано.",
      success_notify:
        "Коли ваш обліковий запис буде схвалено, ми надішлемо вам повідомлення на електронну пошту.",
      success_go_to: "ПЕРЕЙТИ ДАЛІ",
    },
  };

  private readonly _defaultLang: LocaleType = "en";

  public get dictionary() {
    return this._dictionaries[this._locale];
  }

  public get locale(): LocaleType {
    return this._locale;
  }

  public set(locale: LocaleType) {
    this._locale = Object.keys(this._dictionaries).includes(locale)
      ? locale
      : this._defaultLang;
  }
}
