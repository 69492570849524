import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";

import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "src/environments/environment";

export type NTMethodType = "post" | "get" | "put" | "patch" | "delete";

export type IApiResponse<T> = T | { success: boolean };

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private readonly http: HttpClient) {}

  public request<T>(
    method: NTMethodType,
    url: string,
    options: { body?: any; params?: HttpParams }
  ): Observable<IApiResponse<T>> {
    return this.http
      .request<T>(method, `${environment.baseApiUrl}${url}`, options)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error("ApiService:: ", error);
          return of({ success: false, errorMessage: error.error.message });
        })
      );
  }
}
