export const MONTHS: string[] = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export enum InputTypeEnum {
  TEXT = "text",
  EMAIL = "email",
  TEL = "tel",
  NUMBER = "number",
  FILE = "file",
  DATE = "date",
  CHECKBOX = "checkbox",
  PERCENT = "percent",
  SELECT = "select",
}

export interface IFiles {
  files: any[];
  name: string;
  isValid: boolean;
}

export interface ISurveyTemplate {
  readonly title: string;
  readonly name: string;
  readonly sections: ISection[];
  readonly whiteLabel: IWhiteLabel;
}

export interface ISection {
  readonly id: number;
  readonly name: string;
  readonly title?: string;
  readonly subTitle: string;
  readonly span?: string;
  readonly subSpan: string;
  readonly questions?: IQuestion[];
  readonly connectedWith?: string;
  readonly connectedText?: string;
}
export interface IQuestion {
  readonly id: number;
  readonly name: string;
  readonly type: string;
  readonly label?: string;
  readonly required?: true;
  readonly placeholder?: string;
  answers?: any;
}

export interface ISendEmailDto {
  email: string;
  name: string;
  reason: string;
  message?: string;
}

export class IAnswersDto {
  readonly answers: ICompletedSections;
  readonly files: FormData;
}

export interface ICompletedSections {
  readonly [key: string]: ICompletedAnswer;
}

export interface ICompletedAnswer {
  readonly [key: string]: string;
}

export interface IOfficer {
  id: number;
  busy: IMeetingDate[];
}

export interface IConvertedOfficer {
  id: number;
  busy: IConvertedMeetingDate[];
}

export interface IConvertedMeetingDate {
  start: moment.Moment;
  end: moment.Moment;
}

export interface IMeetingDate {
  start: string;
  end: string;
}

export interface ITimezone {
  display: string;
  name: string;
  offset: number;
  timezone: string;
}

export interface ITimeSelectItems {
  value: moment.Moment;
  text: string;
  availableOfficers: number[];
}

export interface IWhiteLabel {
  landingLink: string;
  linkColor: string;
  logoLink: string;
  logoLinkVector: string;
  mainColor: string;
  supportEmail: string;
  titleName: string;
  webLink: string;
  phoneNumber: string;
  supportJson: any;
}
