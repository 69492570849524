<div [formGroup]="form">
  <ng-container [ngSwitch]="conf?.type">
    <app-select [form]="form" [conf]="conf" *ngSwitchCase="'select'">
    </app-select>

    <textarea
      *ngSwitchCase="'textarea'"
      [placeholder]="conf?.placeholder"
      [formControlName]="conf?.name"
      [class]="'input input--' + conf.type"
    ></textarea>

    <div [class]="'input input--' + conf.type" *ngSwitchCase="'file'">
      <app-file-uploader
        [form]="form"
        [conf]="conf"
        (files$)="emitFiles($event)"
      ></app-file-uploader>
    </div>

    <div [class]="'input input--' + conf.type" *ngSwitchCase="'radio'">
      <legend>{{ conf.label }}</legend>
      <nb-radio-group [formControlName]="conf.name">
        <nb-radio *ngFor="let item of conf.answers" [value]="item.value">{{
          item.text
        }}</nb-radio>
      </nb-radio-group>
    </div>

    <div [class]="'input input--' + conf.type" *ngSwitchCase="'checkbox'">
      <nb-checkbox [formControlName]="conf?.name">
        {{ getCheckboxLabel(conf)[0] }}
        <ng-container *ngIf="getCheckboxLabel(conf)[1]">
          <a [href]="conf.placeholder" target="_blank" rel="noreferrer">
            {{ getCheckboxLabel(conf)[1] || "" }}</a
          >
          {{ getCheckboxLabel(conf)[2] || "" }}
        </ng-container>
      </nb-checkbox>
    </div>

    <div [class]="'input input--' + conf.type" *ngSwitchCase="'percent'">
      <p>
        <label [for]="conf.id">{{ conf.label }}</label>
        <input
          [type]="'number'"
          [formControlName]="conf?.name"
          [name]="conf.name"
          [id]="conf.id"
        />
        %
      </p>
    </div>
    <ng-container *ngSwitchCase="'date'">
      <app-meeting-date
        *ngIf="conf.name === 'meetingTime'"
        [conf]="conf"
        [form]="form"
      ></app-meeting-date>
      <app-date
        *ngIf="conf.name !== 'meetingTime'"
        [conf]="conf"
        [form]="form"
      ></app-date>
    </ng-container>

    <input
      *ngSwitchDefault
      [class]="'input input--' + conf.type"
      [type]="conf?.type"
      [placeholder]="
        conf?.required ? conf?.placeholder : conf?.placeholder + ' *'
      "
      [formControlName]="conf?.name"
    />
  </ng-container>
</div>
