import { Injectable } from "@angular/core";
import { HttpParams } from "@angular/common/http";

import { Observable } from "rxjs";

import { ApiService, IApiResponse } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class SurveyService {
  private readonly routes = {
    base: "survey",
    compliance: "meeting/compliance",
    timeZones: "timeZone/list",
  };

  constructor(protected apiService: ApiService) {}

  public get(
    token: string,
    locale: string,
    redirectUrl?: string
  ): Observable<IApiResponse<any>> {
    const options: { params?: HttpParams } = {};

    if (locale) options.params = new HttpParams().append("locale", locale);

    return this.apiService.request<any>(
      "get",
      `${this.routes.base}/${token}`,
      options
    );
  }

  public post(
    token: string,
    locale: string,
    data: FormData
  ): Observable<IApiResponse<any>> {
    data.append("locale", locale);

    return this.apiService.request<any>(
      "post",
      `${this.routes.base}/${token}`,
      { body: data }
    );
  }

  public getTimezones() {
    return this.apiService.request<any>(
      "get",
      `${this.routes.base}/${this.routes.timeZones}`,
      {}
    );
  }

  public getCompliance() {
    return this.apiService.request<any>("get", `${this.routes.compliance}`, {});
  }
}
