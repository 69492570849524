import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

import { ApiService, IApiResponse } from "./api.service";
import { LocaleService } from "./locale.service";

@Injectable({
  providedIn: "root",
})
export class SelectService {
  private readonly _cache: Record<string, Observable<IApiResponse<any>>> = {};

  constructor(
    private readonly _apiService: ApiService,
    private readonly _localeService: LocaleService
  ) {}

  public get<T>(field: string): Observable<IApiResponse<T>> {
    return (
      this._cache[field] ??
      (this._cache[field] = this.request<T>(field).pipe(shareReplay(1)))
    );
  }

  /* HELPERS */

  private request<T>(field: string): Observable<IApiResponse<T>> {
    const params = new HttpParams().append(
      "locale",
      this._localeService.locale
    );

    return this._apiService.request<T>("get", `survey/${field}/list`, {
      params,
    });
  }
}
