import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import {
  NbThemeModule,
  NbLayoutModule,
  NbDatepickerModule,
  NbRadioModule,
  NbCheckboxModule,
  NbTimepickerModule,
} from "@nebular/theme";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { LogoComponent } from "./components/logo/logo.component";
import { SuccessComponent } from "./components/success/success.component";
import { FORM_COMPONENTS } from "./components/forms";
import { NbMomentDateModule, NbMomentDateService } from "@nebular/moment";
import { SupportComponent } from "./components/support/support.component";
import player from "lottie-web";
import { LottieModule } from "ngx-lottie";
import { registerLocaleData } from "@angular/common";
import localeRu from "@angular/common/locales/ru";
import localeUk from "@angular/common/locales/uk";

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    ...FORM_COMPONENTS,
    HeaderComponent,
    LogoComponent,
    SuccessComponent,
    SupportComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    HttpClientModule,
    NbThemeModule.forRoot({ name: "default" }),
    NbLayoutModule,
    AppRoutingModule,
    NbDatepickerModule.forRoot(),
    NbMomentDateModule,
    NbTimepickerModule.forRoot(),
    NbRadioModule,
    NbCheckboxModule,
    BrowserAnimationsModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    NbMomentDateService,
    {
      provide: LOCALE_ID,
      useFactory: () => {
        let startIndex = location.search.indexOf("locale") + 7;
        let lang = location.search.includes("locale")
          ? location.search.split("").splice(startIndex, 2).join("")
          : "en";

        switch (lang) {
          case "ru": {
            registerLocaleData(localeRu);

            break;
          }

          case "uk": {
            registerLocaleData(localeUk);

            break;
          }
        }

        return lang;
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
