import { Component, OnInit, Input, Injector } from "@angular/core";
import { IQuestion, InputTypeEnum, MONTHS } from "src/app/core/models";
import { FormGroup } from "@angular/forms";
import { NbMomentDateService } from "@nebular/moment";
import * as moment from "moment-timezone";
import { LocaleService } from "src/app/core/services/locale.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-date",
  templateUrl: "./date.component.html",
  styleUrls: ["./date.component.scss"],
})
export class DateComponent implements OnInit {
  @Input() public readonly conf: IQuestion;
  @Input() public readonly form: FormGroup;

  public maxDate: moment.Moment;
  public minDate: moment.Moment;
  public today = moment();
  public selectedDate: moment.Moment;
  public pickerDay: string;
  public pickerMonth: string;
  public pickerYear: string;
  public readonly months: string[] = MONTHS;

  protected readonly dateService: NbMomentDateService;

  constructor(
    injector: Injector,
    protected readonly localeService: LocaleService
  ) {
    this.dateService = injector.get(NbMomentDateService);
  }

  public get dictionary() {
    return this.localeService.dictionary;
  }

  ngOnInit() {
    this.initFormControl();
  }

  protected initFormControl() {
    this.maxDate =
      this.conf.name === "birthDay" || this.conf.name === "dateOfBirth"
        ? this.today.add(-18, "year")
        : null;
  }

  public onDateChange(date: moment.Moment) {
    const currentDate = new Date();

    this.selectedDate = date;
    this.pickerYear = date.year().toString();
    this.pickerDay = date.date().toString();
    this.pickerMonth = this.months[date.month().toString()];

    let timeDate = `${this.prepareServerDate(new Date(date.toString()))}T${
      currentDate.getHours() +
      ":" +
      currentDate.getMinutes() +
      ":" +
      currentDate.getSeconds()
    }`;

    this.form.controls[this.conf.name].setValue(timeDate);
  }

  public filter(date: moment.Moment) {
    return true;
  }

  public prepareServerDate = (date: Date): string => {
    if (date) {
      return new DatePipe("en-GB").transform(date, "yyyy-MM-dd");
    }
    return null;
  };
}
