<div class="logo" *ngIf="whiteLabel">
  <a [href]="whiteLabel?.webLink">
    <img
      width="300"
      target="_blank"
      [src]="whiteLabel?.logoLinkVector"
      [alt]="whiteLabel?.titleName"
      [title]="whiteLabel?.titleName"
    />
  </a>
</div>

<div class="loader-backdrop" *ngIf="isLoading">
  <div class="loader"></div>
</div>

<div
  class="logo logo--error"
  *ngIf="serverError || (!whiteLabel && !isLoading)"
>
  <img
    width="300"
    src="assets/icons/error-image.svg"
    alt="niko-technologies"
    title="Niko-technologies"
  />
</div>
