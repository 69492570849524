<section>
  <div *ngIf="serverError" class="result-message server-error">
    <span class="text-danger">{{ serverErrorMessage }}</span>
  </div>
  <form *ngIf="conf" [formGroup]="form" (ngSubmit)="submit()">
    <fieldset
      *ngFor="let section of conf.sections"
      [formGroupName]="section?.name"
    >
      <h1 *ngIf="section.title">
        {{ section.title }}
      </h1>

      <nb-checkbox
        *ngIf="section.connectedWith"
        (checkedChange)="toggle($event)"
        [checked]="isCheckboxChecked"
        class="same-as"
        >{{ section.connectedText }}</nb-checkbox
      >
      <ng-container *ngFor="let controlConf of section.questions">
        <app-form-controls
          [form]="form.controls[section.name]"
          [conf]="controlConf"
          (change)="onChange()"
          (fileListChange)="getFiles($event)"
        ></app-form-controls>
      </ng-container>

      <div class="line"></div>
    </fieldset>
    <button class="submit" [disabled]="isDisabled()">
      {{ dictionary.finish }}
    </button>
  </form>
  <div
    class="result-message"
    *ngIf="form?.pristine && form?.untouched && isAlreadySent"
  >
    <span class="text-danger" *ngIf="isFailed">{{ failedMsg }}</span>
    <span *ngIf="!isFailed">{{ successMsg }}</span>
  </div>

  <div class="loader-backdrop" *ngIf="isLoading">
    <div class="loader"></div>
  </div>
</section>
