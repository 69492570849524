<div [class]="'input input--' + conf.type">
  <label [for]="conf.id">{{ conf.label }}</label>
  <input
    #dateInput
    [nbDatepicker]="dateTime"
    value="{{ pickerDay }}"
    (click)="dateInput.click()"
    type="text"
    style="opacity: 0; width: 0; border: none; height: 0; padding: 0"
    [id]="conf.id"
  />

  <nb-datepicker
    (dateChange)="onDateChange($event)"
    #dateTime
    [max]="maxDate"
    [min]="minDate"
    [date]="today"
    class="personal-datetime-picker"
    style="display: none"
    [filter]="filter.bind(this)"
  >
  </nb-datepicker>

  <div class="date-container">
    <input
      value="{{ pickerDay }}"
      (click)="dateInput.click()"
      type="text"
      [placeholder]="this.dictionary.dd"
      class="day"
      readonly
    />
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="9.44189"
        y1="0.233944"
        x2="0.441894"
        y2="17.2339"
        stroke="#D7DEE8"
      />
    </svg>
    <input
      value="{{ this.dictionary[pickerMonth?.toLowerCase()] || pickerMonth }}"
      (click)="dateInput.click()"
      type="text"
      [placeholder]="this.dictionary.month"
      class="month"
      readonly
    />
    <span class="triangle" (click)="dateInput.click()"
      ><svg
        width="8"
        height="5"
        viewBox="0 0 8 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4 5L0.535898 0.5L7.4641 0.5L4 5Z" fill="#969696" />
      </svg>
    </span>
    <svg
      width="10"
      height="18"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="after-month"
    >
      <line
        x1="9.44189"
        y1="0.233944"
        x2="0.441894"
        y2="17.2339"
        stroke="#D7DEE8"
      />
    </svg>

    <input
      value="{{ pickerYear }}"
      (click)="dateInput.click()"
      type="text"
      [placeholder]="this.dictionary.year"
      class="year"
      readonly
    />
  </div>
</div>
