import { Component, OnInit, Input } from "@angular/core";
import { AbstractControl, FormGroup, Validators } from "@angular/forms";
import { IQuestion } from "src/app/core/models";
import { SelectService } from "src/app/core/services/select.service";
import { Observable } from "rxjs";
import { DataTransferService } from "src/app/core/services/data-transfer.service";

@Component({
  selector: "app-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
})
export class SelectComponent implements OnInit {
  @Input() public readonly form: FormGroup;
  @Input() public conf: IQuestion;

  public isAnswersLoading: boolean;
  public isOtherSelected: boolean;
  public control: AbstractControl;
  private disabledOptions = [];

  public get placeholder(): string {
    return this.isAnswersLoading
      ? "Loading, please wait..."
      : this.isOtherSelected
      ? "Other"
      : this.conf.placeholder;
  }

  constructor(
    private readonly dataTransferService: DataTransferService,
    private readonly selectService: SelectService
  ) {}

  ngOnInit() {
    this.initFormControl();
    this.dataTransferService
      .onDataObservable$<string[] | number[]>(this.conf.name)
      .subscribe((data) => (this.disabledOptions = data));
  }

  private initFormControl() {
    this.control = this.form.controls[this.conf.name];
    if (!this.conf.answers.length) {
      this.getOptions$(this.conf.name).subscribe((res) => {
        this.conf.answers = res;
        this.isAnswersLoading = false;
      });
    }
  }

  public isOptionDisabled(option: string | number): boolean {
    if (this.disabledOptions.length) {
      return !this.disabledOptions.some((item) => item === option);
    }
  }

  private getOptions$(fieldName: string): Observable<any> {
    this.isAnswersLoading = true;
    return this.selectService.get(fieldName);
  }

  public onSelectChange(item: string) {
    if (item === "other") {
      this.control.setValue(null);
      this.control.setValidators([
        Validators.required,
        Validators.minLength(3),
      ]);
      this.control.updateValueAndValidity;
      this.isOtherSelected = true;
      this.control.markAsUntouched;
    } else this.isOtherSelected = false;
  }

  public onDescriptionChange(event: Event) {
    this.control.setValue((event.target as HTMLInputElement).value);
  }
}
