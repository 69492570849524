import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { IQuestion, IFiles } from "src/app/core/models";
import { FormGroup } from "@angular/forms";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

@Component({
  selector: "app-file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"],
})
export class FileUploaderComponent implements OnInit {
  @Input() public readonly conf: IQuestion;
  @Input() public readonly form: FormGroup;
  @Output() public files$: EventEmitter<IFiles> = new EventEmitter<IFiles>();

  public uploadError: boolean;
  public uploadErrorMessage: string;

  public readonly validFileTypes = [
    "jpg",
    "jpeg",
    "png",
    "doc",
    "docx",
    "pdf",
    "tif",
    "tiff",
  ];

  public fileList: IFiles;
  constructor() {}

  ngOnInit(): void {
    this.fileList = {
      files: [],
      name: this.conf.name,
      isValid: true,
    };
    this.uploadErrorMessage = `Please, upload no more than 40 files in formats: ${this.validFileTypes.join(
      ", "
    )}.`;
  }

  public uploadFile({ target: { files } }: HTMLInputEvent) {
    this.fileList.files.push(...Array.from(files));
    this.fileList.files.forEach((file) => {
      this.validFileTypes.forEach((type) => {
        if (file.type.split("/")[1] === type) {
          file.isValid = true;
        }
      });
    });
    this.fileList.isValid = this.validateFiles();
    this.files$.emit(this.fileList);
  }

  private validateFiles(): boolean {
    return (
      this.fileList.files.length <= 12 &&
      this.fileList.files.length >= 0 &&
      this.fileList.files.every((file) => file.isValid)
    );
  }

  public deleteFile(fileName) {
    const fileIndex = this.fileList.files.findIndex(
      (item) => item.name === fileName
    );
    this.fileList.files.splice(fileIndex, 1);

    this.fileList.isValid = this.validateFiles();
    this.files$.emit(this.fileList);
  }

  public convertFileTypesToString(types: string[]): string {
    return types.map((type) => `.${type}`).join(", ");
  }
}
