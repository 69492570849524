import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { FormComponent } from "./components/forms/form/form.component";
import { SuccessComponent } from "./components/success/success.component";

const routes: Routes = [
  {
    path: "success",
    component: SuccessComponent,
  },
  {
    path: ":token",
    component: FormComponent,
  },
  {
    path: "",
    component: FormComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
