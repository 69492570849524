import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, FormControl } from "@angular/forms";

import { combineLatest, Observable } from "rxjs";

import { finalize, map, switchMap, tap } from "rxjs/operators";

import { ISurveyTemplate, ISection } from "src/app/core/models";
import { SurveyService } from "src/app/core/services/survey.service";
import { DataTransferService } from "src/app/core/services/data-transfer.service";
import { getValidators } from "src/app/core/helpers/validators";
import { LocaleService } from "src/app/core/services/locale.service";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
})
export class FormComponent implements OnInit {
  public formData: FormData;
  public conf: ISurveyTemplate | any;

  public serverErrorMessage: string;

  public isFailed: boolean;
  public isAlreadySent: boolean;
  public isLoading: boolean;
  public form: FormGroup;
  public birthDate: Date;
  public sectionWithCheckboxName: string;
  public connectedSectionName: string;
  public isCheckboxChecked: boolean;

  private token: string;
  private redirectUrl: string | null;
  public serverError: boolean;
  public fileList;
  public isUploadValid: boolean;

  public get dictionary() {
    return this.localeService.dictionary;
  }

  public get failedMsg() {
    return this.dictionary.failedMsg;
  }

  public get successMsg() {
    return this.dictionary.successMsg;
  }

  private get queryParams() {
    return this.redirectUrl ? { redirectUrl: this.redirectUrl } : {};
  }

  protected get dto(): FormData {
    this.formData = new FormData();
    const answers = this.form.value;
    for (const key in answers) {
      if (answers.hasOwnProperty(key)) {
        delete answers[key].upload;
      }
    }
    if (this.formData.has("answers")) this.formData.delete("answers");

    this.formData.append(`answers`, JSON.stringify(answers));

    for (const key in this.fileList) {
      if (this.fileList.hasOwnProperty(key)) {
        if (key !== "isValid") {
          this.fileList[key].files.forEach((file: File, index) => {
            this.formData.append(
              `files[]`,
              file,
              `${key}-${index}.${
                file.name.split(".")[file.name.split(".").length - 1]
              }`
            );
          });
        }
      }
    }

    return this.formData;
  }

  private get token$(): Observable<string> {
    return this.route.params.pipe(
      map(({ token }) => token),
      tap((token) => {
        this.token = token;
      })
    );
  }

  private get locale$(): Observable<string> {
    return this.route.queryParams.pipe(
      map(({ locale }) => locale),
      tap((locale) => {
        this.localeService.set(locale);
      }),
      map(() => this.localeService.locale)
    );
  }

  private get redirectUrl$(): Observable<string> {
    return this.route.queryParams.pipe(
      map(({ redirectUrl }) => redirectUrl),
      tap((redirectUrl) => {
        this.redirectUrl = redirectUrl;
      })
    );
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly surveyService: SurveyService,
    private readonly dataTransferService: DataTransferService,
    private readonly titleService: Title,
    private readonly localeService: LocaleService
  ) {
    this.isCheckboxChecked = false;
    this.fileList = {};
  }

  ngOnInit() {
    this.getTemplate();
  }

  private async getTemplate() {
    combineLatest([this.token$, this.locale$, this.redirectUrl$])
      .pipe(
        switchMap(([token, locale, redirectUrl]) =>
          this.surveyService.get(token, locale, redirectUrl)
        )
      )
      .subscribe((conf) => {
        if (conf.success === false) {
          conf.errorMessage === "Survey already completed"
            ? (this.serverErrorMessage = this.dictionary.surveyErrorMessage)
            : (this.serverErrorMessage = this.dictionary.serverErrorMessage);

          this.serverError = true;
          this.dataTransferService.setData("serverError", true);
        } else {
          this.dataTransferService.setData("serverError", false);
          this.setWhiteLabel(conf.whiteLabel);
          this.conf = conf;
          this.sortConfByPosition();

          this.initForm(conf);
        }
      });
  }

  private setWhiteLabel(whiteLabel) {
    document.documentElement.style.setProperty(
      "--background-tertiary",
      whiteLabel.mainColor
    );
    document.documentElement.style.setProperty(
      "--background-tertiary-lighten",
      whiteLabel.mainColorLighten
    );
    this.titleService.setTitle(
      whiteLabel.titleName + ` | ${this.dictionary.survey}`
    );
    const favIcon: HTMLLinkElement = document.querySelector("#favicon");
    favIcon.href =
      whiteLabel.faviconLink ||
      "https://niko-technologies-public.s3.eu-central-1.amazonaws.com/images/favicon/niko-tech.ico";
    this.dataTransferService.setData("whiteLabel", whiteLabel);
  }

  private sortConfByPosition() {
    this.conf.sections.sort((a, b) => a.position - b.position);
    this.conf.sections.forEach((item) =>
      item.questions.sort((a, b) => a.position - b.position)
    );
  }

  public getFiles(fileList) {
    this.fileList[fileList.name] = {};
    this.fileList[fileList.name].files = fileList.files;
    if (fileList.isValid === true) {
      this.fileList[fileList.name].isValid = true;
    } else {
      this.fileList[fileList.name].isValid = false;
    }
    let isValid = true;
    for (const key in this.fileList) {
      if (this.fileList.hasOwnProperty(key)) {
        if (key !== "isValid" && isValid) {
          this.fileList[key].isValid ? (isValid = true) : (isValid = false);
        }
      }
    }
    isValid ? (this.fileList.isValid = true) : (this.fileList.isValid = false);
  }

  private initForm(conf: ISurveyTemplate | any) {
    const sections = {};
    for (const section of conf.sections) {
      this.getConnectedSectionsNames(section);
      const questions = {};
      for (const question of section.questions) {
        questions[question.name] = new FormControl(
          null,
          getValidators(question.type, question.required)
        );
        sections[section.name] = new FormGroup(questions);
      }
      this.form = new FormGroup(sections);
    }
  }

  public submit() {
    this.isLoading = true;

    this.surveyService
      .post(this.token, this.localeService.locale, this.dto)
      .pipe(
        finalize(() => {
          this.isAlreadySent = true;
          this.isLoading = false;
        })
      )
      .subscribe(({ success }) => {
        return success ? this.successFeedback() : this.failFeedback();
      });
  }

  private successFeedback() {
    this.isFailed = false;

    this.form.reset();
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.router.navigate(["/success"], { queryParams: this.queryParams });
  }

  private failFeedback() {
    this.isLoading = false;
    this.isFailed = true;

    this.form.markAsPristine();
    this.form.markAsUntouched();
  }

  public onChange() {
    // if (this.isCheckboxChecked) {
    //   this.isCheckboxChecked = false;
    // }
  }

  private getConnectedSectionsNames(section: ISection) {
    if (section.connectedWith) {
      this.connectedSectionName = section.connectedWith;
      this.sectionWithCheckboxName = section.name;
    }
  }

  public toggle(isChecked: boolean) {
    this.isCheckboxChecked = !this.isCheckboxChecked;

    if (this.sectionWithCheckboxName && isChecked) {
      this.form.controls[this.sectionWithCheckboxName].setValue(
        this.form.controls[this.connectedSectionName].value,
        { onlySelf: false, emitEvent: true }
      );
    }
  }

  public isDisabled() {
    if (
      this.isUploadValid ||
      this.form.invalid ||
      this.form.pending ||
      this.isLoading
    ) {
      return true;
    }
    if (this.fileList.hasOwnProperty("isValid") && !this.fileList?.isValid) {
      return true;
    }
    return false;
  }
}
