<div [formGroup]="form">
  <input
    #uploader
    multiple
    [type]="conf?.type"
    [placeholder]="conf?.placeholder"
    [formControlName]="conf?.name"
    (change)="uploadFile($event)"
    [accept]="convertFileTypesToString(validFileTypes)"
  />
  <button class="button" type="button" (click)="uploader?.click()">
    {{ conf.placeholder }} <span *ngIf="conf.label && !conf.required">*</span>
  </button>
  <p *ngIf="conf?.label">{{ conf?.label }}</p>
  <ul *ngIf="fileList?.files">
    <li *ngFor="let file of fileList.files" [class.typeError]="!file.isValid">
      <span class="close" (click)="deleteFile(file.name)"
        ><svg
          width="10"
          height="10"
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.66504 7.56055L6.10254 4.98828L8.6709 2.44922C8.77636 2.34375 8.77636 2.17188 8.6709 2.06641L7.94043 1.33203C7.88965 1.28125 7.82129 1.25391 7.74902 1.25391C7.67676 1.25391 7.6084 1.2832 7.55762 1.33203L5.00098 3.86328L2.44043 1.33398C2.38965 1.2832 2.32129 1.25586 2.24902 1.25586C2.17676 1.25586 2.1084 1.28516 2.05762 1.33398L1.3291 2.06836C1.22363 2.17383 1.22363 2.3457 1.3291 2.45117L3.89746 4.99023L1.33691 7.56055C1.28613 7.61133 1.25684 7.67969 1.25684 7.75195C1.25684 7.82422 1.28418 7.89258 1.33691 7.94336L2.06738 8.67773C2.12012 8.73047 2.18848 8.75781 2.25879 8.75781C2.32715 8.75781 2.39746 8.73242 2.45019 8.67773L5.00098 6.11524L7.55371 8.67578C7.60644 8.72852 7.6748 8.75586 7.74512 8.75586C7.81347 8.75586 7.88379 8.73047 7.93652 8.67578L8.66699 7.94141C8.71777 7.89063 8.74707 7.82227 8.74707 7.75C8.74511 7.67969 8.71582 7.61133 8.66504 7.56055Z"
            fill="#00a8c9"
          />
        </svg>
      </span>
      {{ file.name }}
    </li>
  </ul>
  <p class="error" *ngIf="fileList.files.length > 0 && !fileList.isValid">
    {{ uploadErrorMessage }}
  </p>
</div>
