<section class="success-page">
  <div class="wrapper">
    <h1>{{ dictionary.success }}</h1>
    <p>
      {{ dictionary.success_submited }}
      <br />
      {{ dictionary.success_notify }}
    </p>
    <div class="img">
      <svg
        width="134"
        height="135"
        viewBox="0 0 134 135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M0.857422 134.143L95.6222 96C86.2601 91.7143 77.5412 86.1428 69.6798 79.4286C56.8873 68.5 46.6676 54.7857 39.7353 39.4286C26.7998 71 13.8644 102.571 0.857422 134.143Z"
            fill="#00A9CA"
          />
          <path
            opacity="0.4"
            d="M20.4391 126.286L1.71484 134.071L33.9463 54.6429C28.9436 69.7143 25.2274 85.1429 22.9404 100.857C21.654 109.857 20.8679 118.357 20.4391 126.286Z"
            fill="white"
          />
          <path
            d="M21.3678 126.5L19.7241 125.929C21.2249 121.357 21.7966 116.429 21.2963 111.643C20.7246 106.286 18.9379 101.143 16.0078 96.6429L17.5086 95.7143C20.5817 100.5 22.5113 105.857 23.083 111.5C23.5118 116.571 23.0115 121.714 21.3678 126.5Z"
            fill="black"
          />
          <path
            d="M34.4467 121.571C34.3038 121.571 34.2323 121.571 34.0894 121.5C33.6606 121.286 33.4462 120.786 33.6606 120.357C35.7331 115.5 37.0195 110.357 37.3769 105.071C37.5198 102.714 37.5198 100.357 37.3054 98.0714C36.6622 91.1429 34.3753 84.4286 30.7305 78.5C29.3726 76.2857 27.8003 74.1429 26.0851 72.2143C25.7993 71.8571 25.7993 71.2857 26.1566 71C26.5139 70.7143 27.0857 70.7143 27.3715 71.0714C29.1582 73.0714 30.7305 75.2857 32.1598 77.5714C36.019 83.7143 38.3059 90.7143 39.0206 97.9286C39.235 100.357 39.3065 102.786 39.1635 105.214C38.8062 110.643 37.5198 116.071 35.3043 121.071C35.1614 121.357 34.8041 121.571 34.4467 121.571Z"
            fill="black"
          />
          <path
            d="M67.036 108.429L65.8926 107.071C72.396 101.286 75.6835 92.7143 74.683 84.0714L76.3982 83.8572C77.5416 93.1429 73.9683 102.286 67.036 108.429Z"
            fill="black"
          />
          <path
            d="M50.9543 25.3571C47.4524 25.3571 44.5938 22.5 44.5938 19C44.5938 15.5 47.4524 12.6429 50.9543 12.6429C54.4561 12.6429 57.3148 15.5 57.3148 19C57.3863 22.5 54.5276 25.3571 50.9543 25.3571ZM50.9543 14.3571C48.3815 14.3571 46.3089 16.4286 46.3089 19C46.3089 21.5714 48.3815 23.6429 50.9543 23.6429C53.5271 23.6429 55.5996 21.5714 55.5996 19C55.5996 16.4286 53.5271 14.3571 50.9543 14.3571Z"
            fill="#34CA88"
          />
          <path
            d="M101.769 15.8571C98.2669 15.8571 95.4082 13 95.4082 9.5C95.4082 6 98.2669 3.14286 101.769 3.14286C105.271 3.14286 108.129 6 108.129 9.5C108.129 13 105.271 15.8571 101.769 15.8571ZM101.769 4.85714C99.1959 4.85714 97.1234 6.92857 97.1234 9.5C97.1234 12.0714 99.1959 14.1429 101.769 14.1429C104.342 14.1429 106.414 12.0714 106.414 9.5C106.343 6.92857 104.27 4.85714 101.769 4.85714Z"
            fill="#34CA88"
          />
          <path
            d="M118.279 62C114.777 62 111.918 59.1429 111.918 55.6429C111.918 52.1429 114.777 49.2857 118.279 49.2857C121.78 49.2857 124.639 52.1429 124.639 55.6429C124.711 59.1429 121.852 62 118.279 62ZM118.279 51C115.706 51 113.633 53.0714 113.633 55.6429C113.633 58.2143 115.706 60.2857 118.279 60.2857C120.851 60.2857 122.924 58.2143 122.924 55.6429C122.924 53.0714 120.851 51 118.279 51Z"
            fill="#34CA88"
          />
          <path
            d="M35.4463 9C36.7488 9 37.8047 7.94467 37.8047 6.64286C37.8047 5.34104 36.7488 4.28571 35.4463 4.28571C34.1438 4.28571 33.0879 5.34104 33.0879 6.64286C33.0879 7.94467 34.1438 9 35.4463 9Z"
            fill="#34CA88"
          />
          <path
            d="M83.4014 34.8571C84.7039 34.8571 85.7598 33.8018 85.7598 32.5C85.7598 31.1982 84.7039 30.1429 83.4014 30.1429C82.0989 30.1429 81.043 31.1982 81.043 32.5C81.043 33.8018 82.0989 34.8571 83.4014 34.8571Z"
            fill="#34CA88"
          />
          <path
            d="M131.642 32.9286C132.944 32.9286 134 31.8733 134 30.5714C134 29.2696 132.944 28.2143 131.642 28.2143C130.339 28.2143 129.283 29.2696 129.283 30.5714C129.283 31.8733 130.339 32.9286 131.642 32.9286Z"
            fill="#34CA88"
          />
          <path
            d="M114.919 81.5714C116.221 81.5714 117.277 80.5161 117.277 79.2143C117.277 77.9125 116.221 76.8571 114.919 76.8571C113.616 76.8571 112.561 77.9125 112.561 79.2143C112.561 80.5161 113.616 81.5714 114.919 81.5714Z"
            fill="#34CA88"
          />
          <path
            d="M122.851 101.857C124.153 101.857 125.209 100.802 125.209 99.5C125.209 98.1982 124.153 97.1429 122.851 97.1429C121.548 97.1429 120.492 98.1982 120.492 99.5C120.492 100.802 121.548 101.857 122.851 101.857Z"
            fill="#34CA88"
          />
          <path
            d="M31.5882 47.5C31.5882 47.8571 31.3023 48.1429 30.945 48.1429H28.658V50.4286C28.658 50.7857 28.3722 51.0714 28.0148 51.0714C27.6575 51.0714 27.3716 50.7857 27.3716 50.4286V48.1429H25.0847C24.7274 48.0714 24.4415 47.7857 24.513 47.4286C24.513 47.1429 24.7988 46.8571 25.0847 46.8571H27.3716V44.5714C27.3716 44.2143 27.6575 43.9286 28.0148 43.9286C28.3722 43.9286 28.658 44.2143 28.658 44.5714V46.8571H30.945C31.3023 46.8571 31.5882 47.1429 31.5882 47.5Z"
            fill="black"
          />
          <path
            d="M99.3382 20.5C99.3382 20.8571 99.0523 21.1429 98.695 21.1429H95.6219V24.2857C95.5504 24.6429 95.2646 24.9286 94.9072 24.8571C94.6214 24.8571 94.3355 24.5714 94.3355 24.2857V21.2143H91.2624C90.9051 21.1429 90.6192 20.8571 90.6907 20.5C90.6907 20.2143 90.9766 19.9286 91.2624 19.9286H94.3355V16.8571C94.407 16.5 94.6928 16.2143 95.0502 16.2857C95.336 16.2857 95.6219 16.5714 95.6219 16.8571V19.9286H98.6235C98.9808 19.8571 99.3382 20.1429 99.3382 20.5Z"
            fill="black"
          />
          <path
            d="M121.852 41.7857C121.852 42.1429 121.566 42.4286 121.209 42.4286H118.922V44.7143C118.993 45.0714 118.707 45.4286 118.35 45.4286C117.993 45.5 117.635 45.2143 117.635 44.8571C117.635 44.7857 117.635 44.7857 117.635 44.7143V42.4286H115.348C114.991 42.4286 114.705 42.1429 114.705 41.7857C114.705 41.4286 114.991 41.1429 115.348 41.1429H117.635V38.8571C117.707 38.5 117.993 38.2143 118.35 38.2857C118.636 38.2857 118.922 38.5714 118.922 38.8571V41.1429H121.209C121.566 41.1429 121.852 41.4286 121.852 41.7857Z"
            fill="black"
          />
          <path
            d="M35.3041 18.5C35.3041 18.8571 35.0182 19.1429 34.6609 19.1429H32.3739V21.4286C32.3025 21.7857 32.0166 22.0714 31.6593 22C31.3734 22 31.0875 21.7143 31.0875 21.4286V19.1429H28.8006C28.4433 19.2143 28.0859 18.9286 28.0859 18.5714C28.0859 18.2143 28.3003 17.8571 28.6577 17.8571C28.7291 17.8571 28.7291 17.8571 28.8006 17.8571H31.0875V15.5714C31.0161 15.2143 31.3019 14.8571 31.6593 14.8571C32.0166 14.7857 32.3739 15.0714 32.3739 15.4286C32.3739 15.5 32.3739 15.5 32.3739 15.5714V17.8571H34.6609C35.0182 17.7857 35.3041 18.1429 35.3041 18.5Z"
            fill="black"
          />
          <path
            d="M95.6233 50.2857C95.6233 50.6429 95.3375 50.9286 94.9801 50.9286H91.9071V54C91.8356 54.3571 91.5497 54.6429 91.1924 54.5714C90.9065 54.5714 90.6207 54.2857 90.6207 54V50.9286H87.5476C87.1903 50.8571 86.9044 50.5714 86.9759 50.2143C86.9759 49.9286 87.2617 49.6429 87.5476 49.6429H90.6207V46.5714C90.6921 46.2143 90.978 45.9286 91.3353 46C91.6212 46 91.9071 46.2857 91.9071 46.5714V49.6429H94.9801C95.3375 49.6429 95.6233 49.9286 95.6233 50.2857Z"
            fill="black"
          />
          <path
            d="M92.9067 82.7857C88.6187 76.3571 82.0437 68.6429 74.4683 61.0714C66.8928 53.5 59.1744 46.9286 52.7424 42.6429C45.7387 37.9286 41.2363 36.5714 39.3067 38.4286C39.1637 38.5714 39.0208 38.7857 38.8779 38.9286H38.9493C38.9493 38.9286 38.8779 39 38.8779 39.0714L0.0714667 133.857C-0.0714667 134.214 0 134.571 0.285867 134.786C0.4288 134.929 0.714667 135.071 0.929067 135.071C1.072 135.071 1.14347 135.071 1.2864 135L96.0512 96.8572C96.1227 96.8572 96.1941 96.7857 96.2656 96.7143C96.5515 96.5714 96.9088 96.4286 97.1232 96.1429C98.9813 94.2857 97.552 89.7857 92.9067 82.7857ZM51.7419 44.0714C58.1024 48.2857 65.7493 54.7857 73.2533 62.2857C80.7573 69.7857 87.1893 77.4286 91.4773 83.7857C96.0512 90.6429 96.6944 94.0714 95.8368 94.9286C95.7653 95 95.6939 95.0714 95.6224 95.0714C86.4747 90.8572 77.9701 85.3571 70.2517 78.7857C57.6736 68.0714 47.5968 54.6429 40.736 39.5714C41.8795 38.9286 45.3099 39.7857 51.7419 44.0714ZM2.50133 132.571L39.8069 41.6428C42.5227 47.5 45.7387 53.0714 49.4549 58.3571C55.0293 66.4286 61.6043 73.7857 69.1083 80.1429C76.5408 86.4286 84.688 91.7857 93.4784 96L2.50133 132.571Z"
            fill="black"
          />
          <path
            d="M54.6017 53.2143C53.9585 53.2143 53.4582 52.7143 53.3867 52.0714C53.3867 51.7143 53.5297 51.4286 53.8155 51.1428C61.3195 44.5714 66.8939 36.1429 70.0385 26.7143C72.7542 18.5 73.5403 9.85714 72.3254 1.28571C72.2539 0.642858 72.6827 0.0714293 73.3259 -0.0714278C73.9691 -0.142856 74.5409 0.285715 74.6838 0.928572C75.8987 9.85714 75.1126 18.8571 72.3254 27.4286C69.0379 37.2857 63.1777 46 55.3878 52.8571C55.1019 53.1428 54.8875 53.2143 54.6017 53.2143Z"
            fill="#34CA88"
          />
          <path
            d="M79.829 79.5C79.4002 79.5 79.0429 79.2857 78.8285 78.9286C78.5426 78.3572 78.6855 77.6429 79.2573 77.3572C85.6893 73.7857 92.4072 70.9286 99.4109 68.7857C101.769 68.0714 104.271 67.4286 106.701 66.8571C107.344 66.7857 107.915 67.2143 108.058 67.8571C108.13 68.4286 107.773 69 107.201 69.1429C104.842 69.7143 102.413 70.2857 100.126 71C93.2648 73.0714 86.6898 75.8571 80.4007 79.3572C80.1863 79.4286 79.9719 79.4286 79.829 79.5Z"
            fill="#34CA88"
          />
          <path
            d="M48.4548 69C47.4543 69.7857 47.2398 71.2857 48.026 72.2857C48.3833 72.7857 48.9551 73.0714 49.5982 73.1429C50.2414 73.2143 50.8132 73.0714 51.3135 72.6429C52.314 71.8571 52.4569 70.3571 51.6708 69.3571C51.3135 68.8571 50.7417 68.5714 50.17 68.5C49.5982 68.5 48.9551 68.6429 48.4548 69Z"
            fill="black"
          />
          <path
            d="M63.1768 87.7143C62.6765 88.0714 62.3907 88.6429 62.3192 89.2857C62.1763 90.5714 63.1053 91.7143 64.3917 91.9286C65.0349 92 65.6067 91.8572 66.1069 91.5C67.1789 90.7143 67.3933 89.2857 66.6787 88.2143C65.8925 87.1429 64.4632 86.9286 63.3912 87.6429C63.3197 87.6429 63.2483 87.7143 63.1768 87.7143Z"
            fill="black"
          />
          <path
            d="M52.3837 94.8571C51.7405 94.8571 46.1661 94.7143 42.7357 89.9286C40.7347 87.0714 40.0915 83.4286 40.9491 80C41.092 79.5 41.5208 79.2857 42.0211 79.3571C42.5213 79.5 42.7357 79.9286 42.6643 80.4286C41.9496 83.3571 42.5213 86.3571 44.1651 88.8571C47.0952 92.9286 51.812 93 52.3837 93C52.884 93 53.2413 93.4286 53.2413 93.8571C53.2413 94.5 52.884 94.8571 52.3837 94.8571Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="134" height="135" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
    <a class="button" [href]="goToRedirectUrl">{{ dictionary.success_go_to }}</a>
  </div>
</section>
