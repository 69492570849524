import { AbstractControl, ValidatorFn, Validators } from "@angular/forms";
import { Validator } from "class-validator";
import { InputTypeEnum } from "../models";

export function getValidators(
  type: string,
  isRequired: boolean
): ValidatorFn[] {
  const validators = [];

  if (isRequired) {
    validators.push(Validators.required);
  }

  switch (type) {
    case InputTypeEnum.TEL:
      validators.push(Validators.minLength(6));
      return validators;

    case InputTypeEnum.EMAIL:
      validators.push(isEmailValidate.bind(this));
      return validators;

    case InputTypeEnum.PERCENT:
      validators.push(Validators.max(100));
      return validators;

    default:
      return validators;
  }
}

export function isEmailValidate(control: AbstractControl): { email: true } {
  const email: string = control?.value;
  const validator = new Validator();

  if (!email || validator.isEmail(email)) {
    return null;
  }

  return { email: true };
}
